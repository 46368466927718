import httpClientShoppingProcess from "src/http/shoppingProcess";
import { iPage } from "src/interfaces/layout";

export default class ParticipatingCompaniesConsumer {
  static get(
    page: iPage,
    shoppingProccessId: string,
    orderBy?: any,
    companyId?: string
  ) {
    const search = new URLSearchParams();

    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());
    search.append("ProcessoCompraId", shoppingProccessId);

    if (companyId) {
      search.append("EmpresaId", companyId);
    }

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    return httpClientShoppingProcess.get(
      `/ProcessoCompraParticipantes?${search.toString()}`
    );
  }

  static created(body: any) {
    return httpClientShoppingProcess.post(`/ProcessoCompraParticipantes`, body);
  }

  static updated(body: any) {
    return httpClientShoppingProcess.put(`/ProcessoCompraParticipantes`, body);
  }

  static delete(id: string) {
    return httpClientShoppingProcess.delete(
      `/ProcessoCompraParticipantes?Id=${id}`
    );
  }

  static pcParticipantesEntrega(body: any) {
    return httpClientShoppingProcess.post(`/PCParticipantesEntrega`, body);
  }
}
