/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useMemo } from "react";
import { toast } from "react-toastify";
import { localStorageStrings } from "src/constants/localStorageStings";
import { maskCnpj } from "src/functions/text";
import useShoppingProccessDemand from "src/hooks/useShoppingProccessDemand";
import ParticipatingCompaniesConsumer from "src/services/participatingCompanies";
import ShoppingProcessDemandItemsConsumer from "src/services/shoppingProcessDemandItems";
import shoppingProcessItemConsumer from "src/services/shoppingProcessItem";
import SolicitacaoCompraConsumer from "src/services/solicitacaoCompra";
import { utils, writeFile } from "xlsx";

const ConsolidateDemandContext = createContext<any>({} as any);

interface vinculateItem {
  produto: {
    id: string;
    codigo: string;
    descricao: string;
    atributos: [];
  } | null;
  solicitacaoCompra: any;
  descricaoDetalhada: string;
}

export function ConsolidateDemandProvider({ children }: { children: any }) {
  const userLogged = localStorage.getItem(localStorageStrings.userLogged);
  const userJson = JSON.parse(`${userLogged}`);

  const { setDemands } = useShoppingProccessDemand();

  const [openRow, setOpenRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogSecondary, setOpenDialogSecondary] = useState(false);
  const [openDialogThree, setOpenDialogThree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentConditionId, setPaymentConditionId] = useState("select");
  const [itemVinculation, setItemVinculation] = useState<vinculateItem>({
    produto: null,
    solicitacaoCompra: null,
    descricaoDetalhada: "",
  });

  const handleRowClick = (rowIndex: any) => {
    setOpenRow(openRow === rowIndex ? null : rowIndex);
  };

  const save = async (processoCompraId: string) => {
    setLoading(true);

    try {
      // flow 1
      const bodyFlowOne = {
        processoCompraId: processoCompraId,
        produtoId: itemVinculation.produto?.id,
        descricaoDetalhada: itemVinculation.descricaoDetalhada,
        processoCompraDemandaItensAtributos: itemVinculation.produto?.atributos
          ?.filter((e: any) => e.selecionar)
          .map((e: any) => {
            return {
              atributosProdutoId: e?.atributosProdutoId,
              obrigatorio: e?.obrigatorio,
            };
          }),
      };
      const flowOne = await ShoppingProcessDemandItemsConsumer.created(
        bodyFlowOne
      );

      if (flowOne.status !== 200) throw flowOne;

      // flow 2
      const flowTwo = await SolicitacaoCompraConsumer.updated(
        itemVinculation.solicitacaoCompra
      );

      if (flowTwo.status !== 200) throw flowTwo;

      // flow 3
      const bodyFlowThree = {
        processoCompraDemandaItemId: flowOne.data.id,
        solicitacaoCompraId: itemVinculation.solicitacaoCompra.id,
      };
      const flowThree =
        await SolicitacaoCompraConsumer.createdPCDemandaItemSolicitacaoCompra(
          bodyFlowThree
        );

      if (flowThree.status !== 200) throw flowThree;

      toast.success("Alteração realizada com sucesso!");
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Desculpe, ocorreu um erro ao executar a alteração!"
      );
    } finally {
      setLoading(false);
    }
  };

  const remove = async (
    solicitacaoCompra: any,
    solicitacaoComprasFromDemand: any,
    demands: any,
    demandId: string
  ) => {
    setLoading(true);

    try {
      const flowOne = await SolicitacaoCompraConsumer.updated({
        solicitacaoCompra: [solicitacaoCompra],
      });

      if (flowOne.status !== 200) throw flowOne;

      const flowTwo =
        await SolicitacaoCompraConsumer.deletePCDemandaItemSolicitacaoCompra(
          solicitacaoCompra.id
        );

      if (flowTwo.status !== 200) throw flowTwo;

      const newSolicitacaoCompra = solicitacaoComprasFromDemand.filter(
        (e: any) => e.id !== solicitacaoCompra.id
      );

      const newDemands = demands.map((e: any) => {
        if (e.id === demandId) {
          return {
            ...e,
            solicitacaoCompras: newSolicitacaoCompra,
          };
        } else {
          return e;
        }
      });

      setDemands(newDemands);
      toast.success("Alteração realizada com sucesso!");
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Desculpe, ocorreu um erro ao executar a alteração!"
      );
    } finally {
      setLoading(false);
    }
  };

  const generateCSV = (demands: any, shoppingProccess: any) => {
    const headerItens = {
      comlumn_1: "Código do Item",
      comlumn_2: "Descrição do Item",
      comlumn_3: "Marca",
      comlumn_4: "Volume Mensal",
      comlumn_5: "Volume Total Vigência",
      comlumn_6: "Valor Referencia - Menor valor",
      comlumn_7: "Valor Alvo - Menor valor",
      comlumn_8: "Spend (GMV) Inicial",
    };

    const sheet1Data = [Object.values(headerItens)];
    let totalVolumeMensal = 0;
    let totalVolumeVigencia = 0;
    let totalSpendInicial = 0;

    try {
      // Agrupar demandas pelo produtoCodigo
      const groupedDemands = demands.reduce((acc: any, demand: any) => {
        const codigo = demand?.produtoCodigo;

        if (!acc[codigo]) {
          acc[codigo] = {
            produtoDescricao: demand?.produtoDescricao,
            volumeMensal: 0,
            volumeVigencia: 0,
            valorReferencia: Number.MAX_VALUE,
            valorAlvo: Number.MAX_VALUE,
            marcas: new Set<string>(),
            hasSolicitacaoCompras: false, // Adicionando flag para saber se tem solicitacaoCompras
          };
        }

        // Verificando se existem items em solicitacaoCompras
        if (
          demand?.solicitacaoCompras &&
          demand?.solicitacaoCompras.length > 0
        ) {
          acc[codigo].hasSolicitacaoCompras = true;
        }

        demand?.solicitacaoCompras?.forEach((item: any) => {
          const volumeMensal = item?.quantidade || 0;
          acc[codigo].volumeMensal += volumeMensal;

          acc[codigo].valorReferencia = Math.min(
            acc[codigo].valorReferencia,
            item?.valorReferencia || Number.MAX_VALUE
          );
          acc[codigo].valorAlvo = Math.min(
            acc[codigo].valorAlvo,
            item?.valorAlvo || Number.MAX_VALUE
          );
          item?.solicitacaoMarcas.forEach((marca: any) =>
            acc[codigo].marcas.add(marca.descricao)
          );
        });

        return acc;
      }, {});

      // Processar os grupos para criar as linhas consolidadas
      Object.entries(groupedDemands).forEach(([codigo, group]: any) => {
        // Caso não tenha "solicitacaoCompras" ou seja um array vazio, imprime com as colunas em branco
        if (!group.hasSolicitacaoCompras) {
          sheet1Data.push([
            codigo,
            group.produtoDescricao,
            "",
            "",
            "",
            "",
            "",
            "",
          ]);
        } else {
          const volumeVigencia =
            group.volumeMensal * parseInt(shoppingProccess.monthNumber) || 0;
          const spendInicial = group.valorReferencia * volumeVigencia || 0;

          totalVolumeMensal += group.volumeMensal;
          totalVolumeVigencia += volumeVigencia;
          totalSpendInicial += spendInicial;

          sheet1Data.push([
            codigo,
            group.produtoDescricao,
            Array.from(group.marcas).join(";"),
            group.volumeMensal,
            volumeVigencia,
            group.valorReferencia.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
            group.valorAlvo.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
            spendInicial.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
          ]);
        }
      });
    } catch (error: any) {
      toast.error(error.message);
    }

    // Adicionando a linha de totais
    sheet1Data.push([]); // Linha vazia
    sheet1Data.push([
      "Totais",
      "", // Células vazias
      "",
      totalVolumeMensal.toString(),
      totalVolumeVigencia.toString(),
      "",
      "",
      totalSpendInicial.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }),
    ]);

    // Criando a planilha
    const ws1 = utils.aoa_to_sheet(sheet1Data);

    // Definindo a largura das colunas para ws1
    ws1["!cols"] = [
      { wch: 10 },
      { wch: 70 },
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    // Adicionando estilos à linha "Totais"
    const totalRowIndex = sheet1Data.length - 1; // Última linha
    ws1[`A${totalRowIndex + 1}`].s = { font: { bold: true } }; // "Totais" em negrito

    // Criando e salvando o arquivo Excel
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws1, "Itens");

    writeFile(wb, "consolidado.xls");
  };

  const generateCSVHospital = (demands: any, shoppingProccess: any) => {
    const headerItens = {
      comlumn_1: "Código do Item",
      comlumn_2: "Descrição do Item",
      comlumn_3: "Rede Compradora",
      comlumn_4: "CNPJ Comprador",
      comlumn_5: "Razão Social Comprador",
      comlumn_6: "Código Item Comprador",
      comlumn_7: "Descrição Item Comprador",
      comlumn_8: "Unidade de Medida",
      comlumn_9: "Quantidade por Embalagem",
      comlumn_10: "Marca",
      comlumn_11: "Volume Mensal",
      comlumn_12: "Volume Total Vigência",
      comlumn_13: "Valor Referencia - Menor valor",
      comlumn_14: "Valor Alvo - Menor valor",
      comlumn_15: "Spend (GMV) Inicial",
    };

    const sheet1Data = [Object.values(headerItens)];
    let totalVolumeMensal = 0;
    let totalVolumeVigencia = 0;
    let totalSpendInicial = 0;

    // Preenchendo os dados do array demand.processoCompraDemandaItens
    try {
      demands?.forEach((demand: any) => {
        if (
          !demand?.solicitacaoCompras ||
          demand?.solicitacaoCompras?.length === 0
        ) {
          sheet1Data.push([demand?.produtoCodigo, demand?.produtoDescricao]);
          return;
        }

        demand?.solicitacaoCompras.forEach((item: any) => {
          const volumeMensal = item?.quantidade || 0;
          const volumeVigencia =
            item?.quantidade * parseInt(shoppingProccess.monthNumber) || 0;
          const spendInicial =
            (item?.valorReferencia || 0) * volumeVigencia || 0;

          // Atualizando os totais
          totalVolumeMensal += volumeMensal;
          totalVolumeVigencia += volumeVigencia;
          totalSpendInicial += spendInicial;

          sheet1Data.push([
            demand.produtoCodigo,
            demand.produtoDescricao,
            item.empresaSolicitacao.redeDescricao || "",
            maskCnpj(item.empresaSolicitacao.cnpj) || "",
            item.empresaSolicitacao.razaoSocial,
            item?.produtoEmpresa.codigoProdutoEmpresa,
            item?.produtoEmpresa.descricao,
            item?.produtoEmpresa?.unidade,
            item?.quantidadeEmbalagem || "",
            item.solicitacaoMarcas.map((e: any) => e.descricao).join(";"),
            volumeMensal.toString(),
            volumeVigencia.toString(),
            item?.valorReferencia?.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
            item?.valorAlvo?.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
            spendInicial.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
          ]);
        });
      });
    } catch (error: any) {
      toast.error(error.message);
    }

    // Adicionando a linha de totais
    sheet1Data.push([]); // Linha vazia
    sheet1Data.push([
      "Totais",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      totalVolumeMensal.toString(),
      totalVolumeVigencia.toString(),
      "",
      "",
      totalSpendInicial.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }),
    ]);

    // Criando a planilha
    const ws1 = utils.aoa_to_sheet(sheet1Data);

    // Definindo a largura das colunas para ws1
    ws1["!cols"] = [
      { wch: 10 },
      { wch: 70 },
      { wch: 25 },
      { wch: 20 },
      { wch: 70 },
      { wch: 20 },
      { wch: 70 },
      { wch: 20 },
      { wch: 30 },
      { wch: 50 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];

    // Adicionando estilos à linha "Totais"
    const totalRowIndex = sheet1Data.length - 1; // Última linha
    ws1[`A${totalRowIndex + 1}`].s = { font: { bold: true } }; // "Totais" em negrito

    // Criando e salvando o arquivo Excel
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws1, "Itens");

    writeFile(wb, "hospital.xls");
  };

  const createNegociation = async (items: any[]) => {
    let body: any = [];
    try {
      body = await Promise.all(
        items.flatMap(async (element) => {
          if (
            element.solicitacaoCompras &&
            element.solicitacaoCompras.length > 0
          ) {
            return Promise.all(
              element.solicitacaoCompras.map(async (item: any) => {
                if (item?.processoCompraItemId) {
                  return null; // Pular para o próximo item
                }

                const response = await ParticipatingCompaniesConsumer.get(
                  {
                    page: 0,
                    rowsPerPage: 100,
                    total: 0,
                  },
                  element.processoCompraId,
                  null,
                  item.empresaId
                );

                const {
                  data: {
                    items: [firstItem],
                  },
                } = response;

                return {
                  quantidade: item.quantidade,
                  valorReferencia: item.valorReferencia,
                  produtoId: element.produtoId,
                  compradorId: item.empresaId,
                  condicaoPagamentoId: paymentConditionId,
                  processoCompraId: element.processoCompraId,
                  responsavelComprador: firstItem.responsavelCompradorId,
                  valorAlvo: item.valorAlvo,
                  produtoEmpresaId: item.produtoEmpresaId,
                  solicitacaoCompraId: item.id,
                  usuarioSistemaResponsavel: userJson?.login,
                };
              })
            );
          } else {
            return [];
          }
        })
      ).then((results) => results.flat());
    } catch {
      toast.error("Ocorreu um erro ao selecionar as demandas!");
    }

    body = body.filter((e: any) => e && e);

    if (body.length > 0) {
      await toSendNegociation(body);
    } else {
      toast.info(
        "Não existem itens para serem enviados ou os itens já foram enviados para negociação!"
      );
    }
  };

  const toSendNegociation = async (body: any) => {
    setLoading(true);

    try {
      const response = await shoppingProcessItemConsumer.createdList({
        processoCompraItems: body,
      });

      if (response.status !== 200) throw response;

      toast.success("Negociações Criadas com sucesso!");

      // TODO: aqui faz novamente o request do demandas itens e ao finalizar manda para a tela de dados gerais
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message);
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      openRow,
      setOpenRow,
      openDialog,
      setOpenDialog,
      handleRowClick,
      itemVinculation,
      setItemVinculation,
      loading,
      setLoading,
      save,
      remove,
      generateCSV,
      generateCSVHospital,
      openDialogSecondary,
      setOpenDialogSecondary,
      toSendNegociation,
      openDialogThree,
      setOpenDialogThree,
      paymentConditionId,
      setPaymentConditionId,
      createNegociation,
    };
  }, [
    openRow,
    openDialog,
    itemVinculation,
    loading,
    openDialogSecondary,
    openDialogThree,
    paymentConditionId,
  ]);

  return (
    <ConsolidateDemandContext.Provider value={contextValue}>
      {children}
    </ConsolidateDemandContext.Provider>
  );
}

export default ConsolidateDemandContext;
