/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useMemo } from "react";
import { toast } from "react-toastify";
import AttachmentsConsumer from "src/services/Attachments";

const AttachmentsContext = createContext<any>({} as any);

export function AttachmentsProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [attachmentField, setAttachmentField] = useState<any>(null);

  const get = async (processoCompraId: string) => {
    try {
      setLoading(true);

      const response = await AttachmentsConsumer.get(processoCompraId);

      if (response.status !== 200) throw response;

      setAttachments(response.data);
    } catch (e: any) {
      toast.error(e?.response?.data?.message ?? "Erro ao buscar os anexos!");
    } finally {
      setLoading(false);
    }
  };

  const getFileBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const post = async (processoCompraId: any) => {
    try {
      const file = attachmentField;

      if (file) {
        setLoading(true);
        const regex = /^.*(?=base64,)base64,/;

        const result = await getFileBase64(file);
        const base64 = result as string;

        const body: any = {
          processoCompraId,
          nomeArquivo: file.name,
          documentoBase64: base64?.replace(regex, ""),
          data: new Date(),
        };

        const response = await AttachmentsConsumer.post(body);

        if (response.status !== 200) throw response;
        body.id = response.data.id;

        toast.success("Anexo salvo com sucesso!");

        setAttachments([body, ...attachments]);
      } else {
        toast.info("Favor adicionar um documento!");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? "Erro ao salvar o anexo!");
    } finally {
      setLoading(false);
    }
  };

  const deleted = async (id: string) => {
    try {
      setLoading(true);

      const response = await AttachmentsConsumer.deleted(id);

      if (response.status !== 200) throw response;

      const newAttachments = attachments.filter((e: any) => e.id !== id);

      setAttachments(newAttachments);
    } catch (e: any) {
      toast.error(e?.response?.data?.message ?? "Erro ao deletar o anexo!");
    } finally {
      setLoading(false);
    }
  };

  const download = async (file: any) => {
    try {
      setLoading(true);

      const response = await AttachmentsConsumer.getById(file.id);

      if (response.status !== 200) throw response;

      // Descodifica o base64 e cria o Blob
      const byteCharacters = atob(response.data.conteudo);
      const byteNumbers = new Array(byteCharacters.length)
        .fill(0)
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);

      // Cria um link temporário para download
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = response.data.nomeArquivo;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? "Erro ao fazer o download do arquivo!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      loading,
      setLoading,
      get,
      post,
      deleted,
      attachments,
      setAttachments,
      attachmentField,
      setAttachmentField,
      download,
    };
  }, [loading, attachments, attachmentField]);

  return (
    <AttachmentsContext.Provider value={contextValue}>
      {children}
    </AttachmentsContext.Provider>
  );
}

export default AttachmentsContext;
