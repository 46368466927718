/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Button,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import useConsolidateDemand from "src/hooks/useConsolidateDemand";
import CircularLoading from "src/components/Loading";
import { useEffect } from "react";
import useShoppingProccessDemand from "src/hooks/useShoppingProccessDemand";
import usePaymentCondition from "src/hooks/usePaymentCondition";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import { toast } from "react-toastify";

const DialogPaymentCondition = () => {
  const {
    setOpenDialogThree,
    createNegociation,
    paymentConditionId,
    setPaymentConditionId,
  } = useConsolidateDemand();
  const { itemsToTrade, setItemsToTrade } = useShoppingProccessDemand();
  const { shoppingProcesSelect } = useShoppingProcess();
  const { loading, paymentConditions, getPaymentConditions } =
    usePaymentCondition();

  useEffect(() => {
    if (shoppingProcesSelect?.buyerAdministratorId)
      getPaymentConditions({
        id: shoppingProcesSelect.buyerAdministratorId,
      });
  }, []);

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        Selecione a opção de pagamento
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setPaymentConditionId("select");
          setOpenDialogThree(false);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {loading ? (
        <CircularLoading />
      ) : (
        <DialogContent>
          <Grid container spacing={2} display="flex" justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                labelId="show_select_network"
                id="show_select_network"
                value={paymentConditionId}
                size="small"
                onChange={(event: SelectChangeEvent) =>
                  setPaymentConditionId(event.target.value)
                }
              >
                <MenuItem value="select" disabled>
                  Selecione:
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                {paymentConditions.map((value: any) => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.description}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ marginTop: 2 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<CloseIcon />}
              size="small"
              onClick={() => {
                setPaymentConditionId("select");
                setOpenDialogThree(false);
              }}
            >
              Fechar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              color="primary"
              size="small"
              onClick={async () => {
                if (!paymentConditionId || paymentConditionId === "select")
                  return toast.info("Favor selecione a opção de pagamento");

                await createNegociation(itemsToTrade);

                setPaymentConditionId("select");
                setItemsToTrade([]);
                setOpenDialogThree(false);
              }}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default DialogPaymentCondition;
