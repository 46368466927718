import httpClientShoppingProccess from "src/http/shoppingProcess";
import { iPage } from "src/interfaces/layout";

class SolicitacaoCompraConsumer {
  static get(
    page: iPage,
    processoCompraId?: string,
    sugestao?: boolean,
    companyId?: string
  ) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (processoCompraId) search.append("ProcessoCompraId", processoCompraId);
    if (companyId) search.append("EmpresaId", companyId);
    if (sugestao || !sugestao) search.append("Sugestao", `${sugestao}`);

    return httpClientShoppingProccess.get(
      `/SolicitacaoCompra?${search.toString()}`
    );
  }

  static getType(page: iPage) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    return httpClientShoppingProccess.get(
      `/SolicitacaoCompraTipo?${search.toString()}`
    );
  }

  static async created(body: any) {
    const response = await httpClientShoppingProccess.post(
      `/SolicitacaoCompra`,
      body
    );

    return response;
  }

  static updated(body: any) {
    return httpClientShoppingProccess.put(`/SolicitacaoCompra`, body);
  }

  static deleted(id: string | null | undefined) {
    return httpClientShoppingProccess.delete(`/SolicitacaoCompra?id=${id}`);
  }

  static async getSugestoes(
    page: iPage,
    processoCompraId?: string,
    companyId?: string
  ) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (processoCompraId) search.append("ProcessoCompraId", processoCompraId);
    if (companyId) search.append("EmpresaId", companyId);

    const response = await httpClientShoppingProccess.get(
      `/PCParticipantesSugestoes?${search.toString()}`
    );

    return response;
  }

  static async createdSugestoes(body: any) {
    const response = await httpClientShoppingProccess.post(
      `/PCParticipantesSugestoes`,
      body
    );

    return response;
  }

  static async updatedSugestoes(body: any) {
    const response = await httpClientShoppingProccess.put(
      `/PCParticipantesSugestoes`,
      body
    );

    return response;
  }

  static async createdPCDemandaItemSolicitacaoCompra(body: any) {
    const response = await httpClientShoppingProccess.post(
      `/PCDemandaItemSolicitacaoCompra`,
      body
    );

    return response;
  }

  static async deletePCDemandaItemSolicitacaoCompra(id: string) {
    const response = await httpClientShoppingProccess.delete(
      `/PCDemandaItemSolicitacaoCompra&SolicitacaoCompraId${id}`
    );

    return response;
  }
}

export default SolicitacaoCompraConsumer;
