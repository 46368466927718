/* eslint-disable no-self-compare */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMediaQuery, useTheme } from "@mui/material";
import { format, subHours, isValid } from "date-fns";
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { maskCnpj } from "src/functions/text";
import { iPage } from "src/interfaces/layout";
import addressConsumer from "src/services/address";
import ParticipatingCompaniesConsumer from "src/services/participatingCompanies";
import ShoppingProcessDemandClientConsumer from "src/services/shoppingProcessDemandClient";
import SolicitacaoCompraConsumer from "src/services/solicitacaoCompra";
import Swal from "sweetalert2";
import { utils, read, writeFile } from "xlsx";

export interface FormValues {
  id: string;
  numero: string;
  descricaoProcesso: string;
  numeroProcesso: string;
  cnpj: string;
  empresa: string;
  compradorResponsavel: string;
  email: string;
  rede: string;
  telefone: string;
  cartaConvite: string;
  codigo: string;
  descricao: string;
  codigoCliente: string;
  descricaoCliente: string;
  quantidade: string;
  valorUnitario: string;
  valorAlvo: string;
  possuiInteresse: boolean;
  produtoEmpresaId?: string;
  produtoId?: string;
  processoCompraDemandaItemId?: string;
  cnpjFornecedor?: string;
  empresaFornecedor?: string;
  compradorResponsavelFornecedor?: string;
  emailFornecedor?: string;
  telefoneFornecedor?: string;
  unidadeMedida: string;
  unidadeId: string;
  quantidadeEmbalagem: number;
  marcas: any[];
  marcaSalvas: any[]; // Campos marca que em texto que já teve as marcas salvas antes
  marca?: string;
  observacao?: string;
}

const DemandCollectionFormContext = createContext<any>({} as any);

export function DemandCollectionFormProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });
  const [open, setOpen] = useState(Array(3).fill(false));
  const [type, setType] = useState(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isFinished, setFinished] = useState<boolean>(false);
  const [demand, setDemand] = useState<any>(null);
  const [address, setAddress] = useState<any>(null);
  const [daysOfWeek, setDaysOfWeek] = useState<any[]>([]);
  const [itemsSuggstionsCreated, setItemsSuggstionsCreated] = useState<any[]>(
    []
  );
  const [expandedAccordion, setExpandedAccordion] = useState<boolean>(false);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const [itemSections, setItemSections] = useState<FormValues[]>([]);
  const [indicacaoItensSections, setIndicacaoItensSections] = useState<
    FormValues[]
  >([]);
  const [indicacaoFornecedores, setIndicacaoFornecedores] = useState<
    FormValues[]
  >([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery("(min-width:1400px)");

  const handleInputChangeMultipleMarket = (index: number, marcas: string[]) => {
    const updatedSections = updateSections(itemSections, index, marcas);
    setItemSections(updatedSections);
  };

  const updateSections = (
    sections: typeof itemSections,
    index: number,
    newMarcas: string[]
  ) => {
    return sections.map((section, i) =>
      i === index ? { ...section, marcas: newMarcas } : section
    );
  };

  const handleInputChange = (event: any, index: number, section: string) => {
    let { name, value, checked } = event.target;

    // Atualiza todos os campos de uma vez
    if (name === "multiChange") {
      if (section === "item") {
        setItemSections((prev) =>
          prev.map((item, idx) =>
            idx === index ? { ...item, ...value } : item
          )
        );
      } else {
        setIndicacaoItensSections((prev) =>
          prev.map((item, idx) =>
            idx === index ? { ...item, ...value } : item
          )
        );
      }
    } else {
      if (name === "possuiInteresse") {
        value = !checked;
      }

      const updateSections = (sections: FormValues[]) => {
        const updatedSections = [...sections];
        updatedSections[index] = { ...updatedSections[index], [name]: value };
        return updatedSections;
      };

      if (section === "item") {
        setItemSections(updateSections(itemSections));
      } else if (section === "indicacaoItens") {
        setIndicacaoItensSections(updateSections(indicacaoItensSections));
      } else if (section === "indicacaoFornecedores") {
        setIndicacaoFornecedores(updateSections(indicacaoFornecedores));
      }
    }
  };

  const handleAddSection = (section: string) => {
    if (section === "item") {
      setItemSections([...itemSections, {} as FormValues]);
    } else if (section === "indicacaoItens") {
      setIndicacaoItensSections([...indicacaoItensSections, {} as FormValues]);
    } else if (section === "indicacaoFornecedores") {
      setIndicacaoFornecedores([...indicacaoFornecedores, {} as FormValues]);
    }
  };

  const handleRemoveSection = (
    index: number,
    section: string,
    item?: { id?: string }
  ) => {
    if (section === "item") {
      setItemSections((prev) => prev.filter((_, i) => i !== index));
    } else if (section === "indicacaoItens") {
      setIndicacaoItensSections((prev) =>
        prev.filter((currentItem, i) =>
          item?.id ? currentItem.id !== item.id : i !== index
        )
      );
    } else if (section === "indicacaoFornecedores") {
      setIndicacaoFornecedores((prev) =>
        prev.filter((currentItem, i) =>
          item?.id ? currentItem.id !== item.id : i !== index
        )
      );
    }
  };

  const handleToggle = (index: any) => {
    setOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const send = async (
    event: any,
    demand: any,
    empresaId: string,
    type: any
  ) => {
    event.preventDefault();

    setLoading(true);

    try {
      const items = itemSections
        .map((e, _: number) => {
          const data: any = {
            empresaId: empresaId,
            processoCompraId: demand?.processoCompraId ?? "",
            solicitacaoCompraTipoId: type?.id ?? "",
            data: subHours(new Date(), 3),
            quantidade: e?.possuiInteresse ? parseInt(e?.quantidade) : 0,
            possuiInteresse: e?.possuiInteresse,
            responsavel: demand?.nomeComprador ?? "",
            sugestao: false,
            codigoProduto: e?.codigoCliente?.toString() ?? null,
            descricaoProduto: e?.descricaoCliente?.toString() ?? null,
            produtoId: e?.produtoId ?? "",
            processoCompraDemandaItemId: e?.processoCompraDemandaItemId,
            marcas: [],
          };

          if (e?.id) {
            data["id"] = e.id;
          }

          if (e?.produtoEmpresaId) {
            data["produtoEmpresaId"] = e?.produtoEmpresaId;
          }

          if (e?.numero) {
            data["numero"] = e.numero;
          }

          if (e?.observacao) {
            data["observacao"] = e.observacao.toString();
          }

          if (e?.unidadeMedida) {
            data["unidadeMedida"] = e.unidadeMedida;
          }

          if (e?.quantidadeEmbalagem) {
            data["quantidadeEmbalagem"] =
              e?.quantidadeEmbalagem?.toString() ?? "0";
          }

          if (e?.marcas && e?.marcas?.length !== 0) {
            data["marcas"] = [
              ...data["marcas"],
              ...e?.marcas
                ?.filter(
                  (e: any) => e.marcaDescricao && e.marcaDescricao !== "Outra"
                )
                .map((item: any) => {
                  const data: any = {
                    descricao: item.marcaDescricao?.trim(),
                  };

                  if (item?.id) data["id"] = item.marcasId;
                  if (e?.id) data["solicitacaoComprasId"] = e.id;

                  return data;
                }),
            ];
          }

          if (e?.marcaSalvas && e?.marcaSalvas?.length > 0) {
            data["marcas"] = [
              ...data["marcas"],
              ...e.marcaSalvas.map((s: any) => {
                return {
                  id: s.id,
                  marcaId: s.id,
                  descricao: s.marcaDescricao,
                };
              }),
            ];
          } else {
            if (e?.marca) {
              if (e.marca?.includes(";")) {
                const i = e.marca?.split(";");
                data["marcas"] = [
                  ...data["marcas"],
                  ...i
                    ?.filter((e: any) => e)
                    .map((item: any) => {
                      const data: any = {
                        descricao: item?.trim(),
                      };

                      if (item?.id) data["id"] = item.marcasId;
                      if (e?.id) data["solicitacaoComprasId"] = e.id;

                      return data;
                    }),
                ];
              } else {
                data["marcas"].push({
                  descricao: e.marca,
                });
              }
            }
          }

          if (e?.possuiInteresse) {
            if (
              e?.valorAlvo === "0" ||
              e?.valorAlvo === "R$ 0,00" ||
              e?.valorAlvo === "R$ 0,0"
            ) {
              data["valorAlvo"] = "";
            } else {
              if (e?.valorAlvo?.toString().includes("R$")) {
                data["valorAlvo"] = e?.valorAlvo
                  ?.toString()
                  ?.replace("R$", "")
                  ?.replaceAll(".", "")
                  ?.replace(",", ".")
                  ?.trim();
              } else {
                data["valorAlvo"] = e?.valorAlvo?.toString();
              }
            }
          }

          if (e?.possuiInteresse) {
            if (
              e?.valorUnitario === "0" ||
              e?.valorUnitario === "R$ 0,00" ||
              e?.valorUnitario === "R$ 0,0"
            ) {
              data["valorReferencia"] = "";
            } else {
              if (e?.valorUnitario?.toString().includes("R$")) {
                data["valorReferencia"] = e?.valorUnitario
                  ?.toString()
                  ?.replace("R$", "")
                  ?.replaceAll(".", "")
                  ?.replace(",", ".")
                  ?.trim();
              } else {
                data["valorReferencia"] = e?.valorUnitario?.toString();
              }
            }
          }

          return data;
        })
        .filter((e: any) => e.possuiInteresse && e);

      const suggestions = indicacaoItensSections
        .map((e, _: number) => {
          const data: any = {
            id: e.id,
            numero: e.numero,
            valorAlvo:
              e?.valorAlvo
                ?.toString()
                ?.replace("R$", "")
                // ?.replaceAll(".", "")
                ?.replace(",", ".")
                ?.trim() ?? "",
            valorReferencia:
              e?.valorUnitario
                ?.toString()
                ?.replace("R$", "")
                // ?.replaceAll(".", "")
                ?.replace(",", ".")
                ?.trim() ?? "",
            empresaId: empresaId,
            processoCompraId: demand?.processoCompraId ?? "",
            solicitacaoCompraTipoId: type?.id ?? "",
            data: new Date(),
            quantidade: parseInt(e?.quantidade) ?? 0,
            possuiInteresse: true,
            responsavel: demand?.nomeComprador ?? "",
            sugestao: true,
            codigoProduto: e?.codigoCliente.toString() ?? null,
            descricaoProduto: e?.descricaoCliente ?? null,
          };

          if (e?.observacao) {
            data["observacao"] = e.observacao?.toString();
          }

          if (e.marcas && e.marcas.length !== 0) {
            data["marcas"] = e.marcas
              ?.filter((e: any) => e?.descricao !== "")
              ?.map((item: any) => {
                return {
                  id: item?.id,
                  solicitacaoComprasId: item?.solicitacaoComprasId,
                  descricao: item?.descricao,
                };
              });
          }

          if (e?.unidadeMedida) {
            data["unidadeMedida"] = e.unidadeMedida;
          }

          if (e?.quantidadeEmbalagem) {
            data["quantidadeEmbalagem"] =
              e?.quantidadeEmbalagem?.toString() ?? "0";
          }

          if (e?.produtoEmpresaId) {
            data["produtoEmpresaId"] = e?.produtoEmpresaId;
          }

          return data;
        })
        .filter((e: any) => e?.possuiInteresse && e);

      const checkIsNotValue = items.filter(
        (element: any) => !element.valorReferencia || !element.valorAlvo
      );

      if (checkIsNotValue.length > 0) {
        return toast.info(
          "Os campos: Valor Referência / Última Compra e Valor Alvo, não podem possuir valores zerados!"
        );
      }

      const response = await SolicitacaoCompraConsumer.created({
        solicitacaoCompra: [...items, ...suggestions],
      });

      if (response.status !== 200) throw response;

      await SolicitacaoCompraConsumer.updatedSugestoes({
        pcParticipantesSugestoes: indicacaoFornecedores
          ?.filter((e: any) => e && e.id)
          ?.map((el: any) => {
            const data: any = {
              processoCompraParticipantesId:
                demand?.processoCompraParticipanteId,
              cnpj: el.cnpjFornecedor,
              nomeEmpresa: el.empresaFornecedor,
              nomeContato: el.compradorResponsavelFornecedor,
              emailContato: el.emailFornecedor,
              telefoneContato: el.telefoneFornecedor,
            };

            if (el?.id) {
              data["id"] = el.id;
            }

            return data;
          }),
      });

      await SolicitacaoCompraConsumer.createdSugestoes({
        pcParticipantesSugestoes: indicacaoFornecedores
          ?.filter((e: any) => e && !e?.id)
          ?.map((el: any) => {
            const data: any = {
              processoCompraParticipantesId:
                demand?.processoCompraParticipanteId,
              cnpj: el.cnpjFornecedor,
              nomeEmpresa: el.empresaFornecedor,
              nomeContato: el.compradorResponsavelFornecedor,
              emailContato: el.emailFornecedor,
              telefoneContato: el.telefoneFornecedor,
            };

            return data;
          }),
      });

      Swal.fire(
        "Sucesso!",
        "Solicitação enviada, você será redirecionado para a página de login.",
        "success"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.replace("https://www.supply4med.com.br/");
        }
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? "Erro ao enviar os dados!");
    } finally {
      setLoading(false);
    }
  };

  const declinou = async (demand: any, empresaId: string, motivo: string) => {
    if (!motivo) return toast.info("Informe o motivo do declinio!");

    setLoading(true);

    try {
      const body: any = {
        id: demand.processoCompraParticipanteId,
        processoComprasId: demand.processoCompraId,
        empresasId: empresaId,
        declinouParticipacao: true,
        justificativaDeclinio: motivo,
      };

      if (demand?.responsavelCompradorId) {
        body["responsavelCompradorId"] = demand?.responsavelCompradorId;
      }

      const response = await ParticipatingCompaniesConsumer.updated(body);

      if (response.status !== 200) throw response;

      Swal.fire(
        "Sucesso!",
        "Declinado com sucesso, você será redirecionado para a página de login.",
        "success"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.replace("https://www.supply4med.com.br/");
        }
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          "Desculpe, não foi possível finalizar a operação!"
      );
    } finally {
      setLoading(false);
    }
  };

  const get = async (processoCompraId?: string, sugestao?: boolean) => {
    setLoading(true);

    try {
      const response = await SolicitacaoCompraConsumer.get(
        page,
        processoCompraId,
        sugestao
      );

      if (response.status !== 200) throw response;

      setItemsSuggstionsCreated(response.data.items);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          "Desculpe, não foi possível buscar os dados da solicitação!"
      );
    } finally {
      setLoading(false);
    }
  };

  const getType = async () => {
    setLoading(true);

    try {
      const response = await SolicitacaoCompraConsumer.getType(page);

      if (response.status !== 200) throw response;

      const currentType = response.data.items.find(
        (e: any) =>
          e.descricao.toUpperCase() ===
          "Solicitação de Demanda de Compra".toUpperCase()
      );

      setType(currentType);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          "Desculpe, não foi possível buscar os dados de tipo da solicitação!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    setLoading(true);

    reader.onload = (event: any) => {
      const data = event?.target.result;
      const workbook = read(data, { type: "binary" });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      const sheetData = utils.sheet_to_json(worksheet, { header: 1 });

      const validData = sheetData.slice(2); // Remove as duas primeiras linhas

      processSpreadsheetData(validData);
    };

    reader.readAsBinaryString(file);
  };

  const processSpreadsheetData = async (sheetData: any) => {
    const rows = sheetData.slice(2);

    const updatedItems = demand.processoCompraDemandaItens.map((item: any) => {
      const correspondingRow = rows.find(
        (row: any) => row[0] === item.produtoCodigo
      );

      if (correspondingRow) {
        const updatedItem = {
          codigo: item.produtoCodigo,
          descricao: item.produtoDescricao,
          codigoCliente: correspondingRow[2] || "",
          descricaoCliente: correspondingRow[3] || "",
          unidadeMedida: correspondingRow[4] || "",
          quantidadeEmbalagem: correspondingRow[5] || "",
          quantidade: correspondingRow[6] || "",
          marca: correspondingRow[7] || "",
          valorUnitario:
            !correspondingRow[8] ||
            correspondingRow[8] === "" ||
            correspondingRow[8] === "0" ||
            correspondingRow[8] === "0.0" ||
            correspondingRow[8] === "0,0" ||
            correspondingRow[8] === "0.00" ||
            correspondingRow[8] === "0,00" ||
            correspondingRow[8] === 0 ||
            correspondingRow[8] === 0.0
              ? ""
              : correspondingRow[8].toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
          valorAlvo:
            !correspondingRow[9] ||
            correspondingRow[9] === "" ||
            correspondingRow[9] === "0" ||
            correspondingRow[9] === "0.0" ||
            correspondingRow[9] === "0,0" ||
            correspondingRow[9] === "0.00" ||
            correspondingRow[9] === "0,00" ||
            correspondingRow[9] === 0 ||
            correspondingRow[9] === 0.0
              ? ""
              : correspondingRow[9].toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
          observacao: correspondingRow[10],
          possuiInteresse: correspondingRow[11] === "SIM" ? true : false,
          produtoEmpresaId: null,
          produtoId: item.produtoId,
          processoCompraDemandaItemId: item.id,
          marcas: [
            {
              id: "",
              marcaDescricao: "Outra",
              marcasId: "",
              produtosId: "",
            },
          ],
        };

        return updatedItem;
      }

      return item;
    });

    setItemSections(updatedItems);

    setLoading(false);
  };

  const handleFileUploadIndications = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    setLoading(true);

    reader.onload = (event: any) => {
      const data = event?.target.result;
      const workbook = read(data, { type: "binary" });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      const sheetData = utils.sheet_to_json(worksheet, { header: 1 });

      const validData = sheetData.slice(2); // Remove as duas primeiras linhas

      processSpreadsheetDataIndications(validData);
    };

    reader.readAsBinaryString(file);
  };

  const processSpreadsheetDataIndications = async (sheetData: any) => {
    const rows = sheetData.slice(2);

    const updatedItems = rows.map((item: any) => {
      handleAddSection("indicacaoItens");

      const correspondingRow = item;

      if (correspondingRow) {
        const updatedItem = {
          codigoCliente: correspondingRow[0] || "",
          descricaoCliente: correspondingRow[1] || "",
          unidadeMedida: correspondingRow[2] || "",
          quantidadeEmbalagem: correspondingRow[3] || "",
          quantidade: correspondingRow[4] || "",
          marcas: [] as {
            descricao: string;
          }[],
          valorUnitario:
            !correspondingRow[6] ||
            correspondingRow[6] === "" ||
            correspondingRow[6] === "0" ||
            correspondingRow[6] === "0.0" ||
            correspondingRow[6] === "0,0" ||
            correspondingRow[6] === "0.00" ||
            correspondingRow[6] === "0,00" ||
            correspondingRow[6] === 0 ||
            correspondingRow[6] === 0.0
              ? ""
              : correspondingRow[6].toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
          valorAlvo:
            !correspondingRow[7] ||
            correspondingRow[7] === "" ||
            correspondingRow[7] === "0" ||
            correspondingRow[7] === "0.0" ||
            correspondingRow[7] === "0,0" ||
            correspondingRow[7] === "0.00" ||
            correspondingRow[7] === "0,00" ||
            correspondingRow[7] === 0 ||
            correspondingRow[7] === 0.0
              ? ""
              : correspondingRow[7].toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
          observacao: correspondingRow[8],
          possuiInteresse: correspondingRow[9] === "SIM" ? true : false,
        };

        if (correspondingRow[5]) {
          if (correspondingRow[5]?.includes(";")) {
            const itemsList = correspondingRow[5]?.split(";");

            itemsList.forEach((element: any) => {
              updatedItem.marcas.push({
                descricao: element || "",
              });
            });
          } else {
            updatedItem.marcas.push({
              descricao: correspondingRow[5] || "",
            });
          }
        }

        return updatedItem;
      }

      return item;
    });

    setIndicacaoItensSections(updatedItems);

    setLoading(false);
  };

  const gererateCSV = (demand: any) => {
    const header_ittens = {
      comlumn_1: "Código",
      comlumn_2: "Descrição",
      comlumn_3: "Código Item Cliente *",
      comlumn_4: "Descrição Item Cliente *",
      comlumn_5: "Unidade de Medida *",
      comlumn_6: "Quantidade de Embalagem",
      comlumn_7: "Volume Mensal *",
      comlumn_8: "Marca Desejada/Homologada",
      comlumn_9: "Valor Últ. Compra (R$) *",
      comlumn_10: "Valor Alvo (R$) *",
      comlumn_11: "Observação",
      comlumn_12: "Possui Interesse ? *",
    };

    const instructions = [
      "Os campos com (*) são obrigatórios de preenchimento.",
      "Preencha os valores nos campos 'Valor Últ. Compra (R$)' e 'Valor Alvo (R$)' com valores numéricos válidos. Exemplo: (Dez reais): 10,00, (Cem reais): 100,00, (Valores com centavos): 1589.76. Informar somente Números.",
      "Ao preencher os valores do campo: Marca Desejada/Homologada, caso tenha mais de uma marca, separá-las com: ; (Ponto e Vírgula).",
    ];

    // Convertendo os cabeçalhos para arrays e adicionando orientações
    const sheet1Data = [
      ...instructions.map((instr) => [instr]), // Instruções de preenchimento
      objectToArray(header_ittens), // Cabeçalhos
    ];

    // Preenchendo os dados do array demand.processoCompraDemandaItens
    demand.processoCompraDemandaItens.forEach((item: any) => {
      sheet1Data.push([
        item.produtoCodigo, // Coluna "Cód"
        item.produtoDescricao, // Coluna "Descrição"
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "SIM",
      ]);
    });

    const ws1 = utils.aoa_to_sheet(sheet1Data);

    // Definindo a largura das colunas para ws1
    ws1["!cols"] = [
      { wch: 10 },
      { wch: 70 },
      { wch: 25 },
      { wch: 70 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws1, "Itens");

    writeFile(wb, "planilha_itens.xls");
  };

  const gererateCSVIndications = () => {
    const header_ittens = {
      comlumn_1: "Código Item Cliente *",
      comlumn_2: "Descrição Item Cliente *",
      comlumn_3: "Unidade de Medida *",
      comlumn_4: "Quantidade de Embalagem",
      comlumn_5: "Volume Mensal *",
      comlumn_6: "Marca Desejada/Homologada",
      comlumn_7: "Valor Últ. Compra (R$) *",
      comlumn_8: "Valor Alvo (R$) *",
      comlumn_9: "Observação",
    };

    const instructions = [
      "Os campos com (*) são obrigatórios de preenchimento.",
      "Preencha os valores nos campos 'Valor Últ. Compra (R$)' e 'Valor Alvo (R$)' com valores numéricos válidos. Exemplo: (Dez reais): 10,00, (Cem reais): 100,00, (Valores com centavos): 1589.76. Informar somente Números.",
      "Ao preencher os valores do campo: Marca Desejada/Homologada, caso tenha mais de uma marca, separá-las com: ; (Ponto e Vírgula).",
    ];

    // Convertendo os cabeçalhos para arrays e adicionando orientações
    const sheet1Data = [
      ...instructions.map((instr) => [instr]), // Instruções de preenchimento
      objectToArray(header_ittens), // Cabeçalhos
    ];

    const ws1 = utils.aoa_to_sheet(sheet1Data);

    // Definindo a largura das colunas para ws1
    ws1["!cols"] = [
      { wch: 25 },
      { wch: 70 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws1, "ItensIndicacao");

    writeFile(wb, "planilha_itens_indicação.xls");
  };

  const objectToArray = (obj: any) => Object.values(obj);

  const getShoppingProcessDemandClient = async (
    shoppingProccessId: string,
    companyId?: string
  ) => {
    setIsError(false);
    setFinished(false);
    setLoading(true);
    try {
      const response = await ShoppingProcessDemandClientConsumer.get(
        page,
        shoppingProccessId,
        companyId
      );

      if (response.status !== 200) throw response;

      if (companyId) {
        const item = response.data[0];

        const startItems = item?.processoCompraDemandaItens?.map(
          (item: any, index: number) => {
            if (
              item.solicitacoesCompras &&
              item.solicitacoesCompras?.length !== 0
            ) {
              const el =
                item.solicitacoesCompras[item.solicitacoesCompras.length - 1];

              updateTableRow(index, {
                produtoId: item.produtoId,
                codigo: item.produtoCodigo,
                descricao: item.produtoDescricao,
                produtoEmpresaId: el?.produtoEmpresa?.id,
                codigoCliente:
                  el?.produtoEmpresa?.codigoProdutoEmpresa || "Não informado",
                descricaoCliente:
                  el?.produtoEmpresa?.descricao || "Não informado",
                quantidade: parseInt(el?.quantidade) || 0,
                valorUnitario:
                  el?.valorReferencia.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }) || 0,
                valorAlvo:
                  el?.valorAlvo.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }) || 0,
                possuiInteresse: el?.possuiInteresse,
                unidadeMedida: el?.unidadeMedida ?? "",
                quantidadeEmbalagem: el?.quantidadeEmbalagem ?? "",
                observacao: el?.observacao ?? "",
                marcas: [
                  {
                    id: "",
                    marcaDescricao: "Outra",
                    marcasId: "",
                    produtosId: "",
                  },
                ],
                marca: el.solicitacaoMarcas
                  .map((e: any) => {
                    return e?.descricao;
                  })
                  .join(";"),
                marcaSalvas: el.solicitacaoMarcas.map((e: any) => {
                  return {
                    id: e.id,
                    marcaDescricao: e.descricao,
                    marcasId: e.id,
                    produtosId: el.produtoEmpresaId,
                  };
                }),
                id: el.id, // ID da solicitação
                numero: el.numero, // Número da solicitação
              });
            } else {
              updateTableRow(index, {
                produtoId: item.produtoId,
                codigo: item.produtoCodigo,
                descricao: item.produtoDescricao,
                possuiInteresse: true,
                processoCompraDemandaItemId: item.id,
                produtosMarcas: item.produtosMarcas,
                marcas: [],
              });
            }

            return {
              produtoId: item.produtoId,
              codigo: item.produtoCodigo,
              descricao: item.produtoDescricao,
              possuiInteresse: true,
              processoCompraDemandaItemId: item.id,
              produtosMarcas: item.produtosMarcas,
            };
          }
        );

        setDemand({
          ...item,
          cnpj: maskCnpj(item.cnpj),
        });

        return startItems;
      } else {
        setDemand(response.data);
        return response.data;
      }
    } catch (e) {
      setIsError(true);
      toast.error("Ops... identificamos um erro ao buscar as demandas!");
    } finally {
      setLoading(false);
    }
  };

  const updateTableRow = (index: number, newData: any) => {
    setItemSections((prevSections) => {
      const updatedSections = [...prevSections];

      updatedSections[index] = {
        ...updatedSections[index],
        ...newData,
      };

      return updatedSections;
    });
  };

  const updateTableRowIndications = (index: number, newData: any) => {
    setIndicacaoItensSections((prevSections) => {
      const updatedSectionsIndications = [...prevSections];

      updatedSectionsIndications[index] = {
        ...updatedSectionsIndications[index],
        ...newData,
      };

      return updatedSectionsIndications;
    });
  };

  const getShoppingProcessDemandClientIndications = async (
    shoppingProccessId: string,
    companyId?: string
  ) => {
    setIsError(false);
    setFinished(false);
    setLoading(true);

    try {
      const response = await SolicitacaoCompraConsumer.get(
        page,
        shoppingProccessId,
        true,
        companyId
      );

      if (response.status !== 200) throw response;

      response.data.items.map((item: any, index: number) => {
        updateTableRowIndications(index, {
          produtoEmpresaId: item?.produtoEmpresaId,
          codigoCliente: item?.codigoProduto || "Não informado",
          descricaoCliente: item?.descricaoProduto || "Não informado",
          quantidade: item?.quantidade || 0,
          valorUnitario:
            item?.valorReferencia.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }) || 0,
          valorAlvo:
            item?.valorAlvo.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }) || 0,
          possuiInteresse: item?.possuiInteresse,
          unidadeMedida: item?.unidadeMedida ?? "",
          quantidadeEmbalagem: item?.quantidadeEmbalagem ?? "",
          observacao: item?.observacao ?? "",
          marcas: item?.marcas
            .filter((e: any) => e === e && e?.descricao)
            .map((e: any) => {
              return {
                id: e?.id,
                marcaDescricao: e?.descricao,
                descricao: e?.descricao,
                solicitacaoComprasId: e.solicitacaoComprasId,
              };
            }),
          id: item.id,
          numero: item.numero,
        });
      });
    } catch (e) {
      setIsError(true);
      toast.error(
        "Ops... identificamos um erro ao buscar as demandas que foram indicadas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const registerAddress = async (empresaid: string) => {
    if (!address?.cep) return;

    setLoading(true);

    let addressId = address?.id;

    try {
      if (!addressId) {
        const bodyAddress = {
          address: address?.endereco,
          cep: address?.cep,
          city: address?.cidade,
          state: address?.estado,
          neighborhood: address?.bairro,
          country: "BR",
          complement: address?.complemento,
          number: address?.numero,
          main: false,
          companyId: empresaid,
          typeAddressId: address.typeAddressId,
          typeAddress: "",
        };

        const saveAddress = await addressConsumer.created(bodyAddress);

        if (saveAddress.status !== 200) throw saveAddress;

        addressId = saveAddress.data.id;
      }

      const body = {
        processoCompraParticipantesId: demand?.processoCompraParticipanteId,
        enderecosId: addressId,
      };

      const response =
        await ParticipatingCompaniesConsumer.pcParticipantesEntrega(body);

      if (response.status !== 200) throw response;

      // eslint-disable-next-line array-callback-return
      const hoursBody = daysOfWeek?.map((el: any) => {
        if (isValid(el.startHour) && isValid(el.endHour)) {
          return {
            diaSemana: el?.day,
            horarioInicio: format(el.startHour, "HH:mm"),
            horarioFim: format(el.endHour, "HH:mm"),
            enderecoId: addressId,
          };
        }
      });

      const responseDeliveryHours = await addressConsumer.hoursDelivery(
        hoursBody.filter((e) => e)
      );

      if (responseDeliveryHours.status !== 200) throw responseDeliveryHours;
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? "Erro ao salvar os endereços de entrega!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleIndicacaoChange = (
    inputValue: string,
    index: number,
    fieldName: string
  ) => {
    setIndicacaoItensSections((prevState: FormValues[]) => {
      const updatedSections: any = [...prevState];
      const currentField = updatedSections[index][fieldName] || [];

      // Divide o input pelo ';'
      const parts = inputValue.split(";");
      const trimmedParts = parts.map((part) => part.trim());

      // Atualiza ou adiciona objetos no array
      const updatedArray = trimmedParts.map((descricao, i) => {
        if (i < currentField.length) {
          // Atualiza objetos existentes
          return { ...currentField[i], descricao };
        } else {
          // Cria novos objetos se necessário
          return {
            descricao,
          };
        }
      });

      // Atualiza o campo no índice correto
      updatedSections[index] = {
        ...updatedSections[index],
        [fieldName]: updatedArray,
      };

      return updatedSections;
    });
  };

  const getShoppingProcessDemandClientIndicationsSupply = async (
    shoppingProccessId: string,
    companyId?: string
  ) => {
    setIsError(false);
    setFinished(false);
    setLoading(true);

    try {
      const response = await ParticipatingCompaniesConsumer.get(
        page,
        shoppingProccessId,
        null,
        companyId
      );

      if (response.status !== 200) throw response;

      if (response.data?.items && response.data.items.length !== 0) {
        const data = response.data?.items[0];

        if (data?.sugestoes && data?.sugestoes.length !== 0) {
          const updatedSections = data.sugestoes?.map((item: any) => ({
            id: item?.idSugestao || "",
            cnpjFornecedor: item?.cnpjSugestao || "",
            empresaFornecedor: item?.razaoSocialSugestao || "",
            compradorResponsavelFornecedor: item?.nomeContatoSugestao || "",
            emailFornecedor: item?.emailContatoSugestao || "",
            telefoneFornecedor: item?.telefoneContatoSugestao || "",
          }));

          setIndicacaoFornecedores(updatedSections);
        }
      }
    } catch (e) {
      setIsError(true);
      toast.error(
        "Ops... identificamos um erro ao buscar as demandas que foram indicadas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      loading,
      setLoading,
      handleChangePage,
      handleChangeRowsPerPage,
      isMobile,
      isLargeScreen,
      handleInputChange,
      handleToggle,
      open,
      handleAddSection,
      indicacaoItensSections,
      handleRemoveSection,
      send,
      itemSections,
      setItemSections,
      getType,
      type,
      setType,
      handleFileUpload,
      getShoppingProcessDemandClient,
      isError,
      setIsError,
      demand,
      setDemand,
      declinou,
      address,
      setAddress,
      daysOfWeek,
      setDaysOfWeek,
      expandedAccordion,
      setExpandedAccordion,
      registerAddress,
      indicacaoFornecedores,
      setIndicacaoFornecedores,
      isFinished,
      setFinished,
      get,
      itemsSuggstionsCreated,
      setItemsSuggstionsCreated,
      handleInputChangeMultipleMarket,
      gererateCSV,
      updateTableRow,
      gererateCSVIndications,
      handleFileUploadIndications,
      getShoppingProcessDemandClientIndications,
      handleIndicacaoChange,
      getShoppingProcessDemandClientIndicationsSupply,
    };
  }, [
    page,
    loading,
    isMobile,
    isLargeScreen,
    open,
    indicacaoItensSections,
    itemSections,
    indicacaoItensSections,
    type,
    isError,
    demand,
    address,
    daysOfWeek,
    expandedAccordion,
    indicacaoFornecedores,
    itemsSuggstionsCreated,
  ]);

  return (
    <DemandCollectionFormContext.Provider value={contextValue}>
      {children}
    </DemandCollectionFormContext.Provider>
  );
}

export default DemandCollectionFormContext;
