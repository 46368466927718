/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useMemo } from "react";
import {
  iCompany,
  iCompanyContextProps,
  iCompanyPage,
} from "src/interfaces/company";
import useLayout from "src/hooks/useLayout";
import companyConsumer from "src/services/company";
import Company from "src/models/Company";
import { toast } from "react-toastify";
import { iPage } from "src/interfaces/layout";
import useAddress from "src/hooks/useAddress";
import useCompanyContact from "src/hooks/useCompanyContact";
import { localStorageStrings } from "src/constants/localStorageStings";
import companyGroupConsumer from "src/services/companyGroups";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import { useNavigate, useParams } from "react-router-dom";
import { addMonths, isAfter, parseISO } from "date-fns";

const CompanyContext = createContext<iCompanyContextProps>(
  {} as iCompanyContextProps
);

export function CompanyProvider({ children }: { children: any }) {
  const router = useNavigate();
  const { lang } = useParams();

  const { disableButtons, setDisableButtons } = useLayout();
  const {
    setCep,
    setAddress,
    setCity,
    setNeighborhood,
    setNumber,
    setState,
    setCountry,
    handleNewSalveApi,
    setMain: setMainAddress,
  } = useAddress();

  const {
    setEmail,
    setRepresentative,
    setMain,
    handleSalveApi: handleSalveContact,
    setTypeId,
    getTypes,
  } = useCompanyContact();

  const [companySelected, setCompanySelected] = useState<iCompany | null>(null);
  const [value, setValue] = useState(0);
  const [companys, setCompanys] = useState<Array<iCompany>>([]);
  const [companysToSearch, setCompanysToSearch] = useState<Array<any>>([]);
  const [typingTimeout, setTypingTimeout] = useState<any>(null);
  const [companysToSearchSupplier, setCompanysToSearchSupplier] = useState<
    Array<any>
  >([]);
  const [companysToSearchBuyer, setCompanysToSearchBuyer] = useState<
    Array<any>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSelectSearch, setLoadingSelectSearch] =
    useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [activeTabsMenu, setActiveTabsMenu] = useState<boolean>(true);
  const [foreignCompany, setForeignCompany] = useState<boolean>(false);

  const [cnpj, setCnpj] = useState<string>("");
  const [socialName, setSocialName] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [nameBuyer, setNameBuyer] = useState<string>("");
  const [nameSupplySearch, setNameSupplySearch] = useState<string>("");
  const [codeOutdoor, setCodeOutdoor] = useState<string>("");
  const [active, setActive] = useState<boolean>(false);
  const [buyer, setBuyer] = useState<boolean>(false);
  const [supplier, setSupplier] = useState<boolean>(false);
  const [administrator, setAdministrator] = useState<boolean>(false);
  const [clickButtonSearch, setClickButtonSearch] = useState<boolean>(false);
  const [network, setNetwork] = useState<string>("select");
  const [openDialogCompanyBuyer, setOpenDialogCompanyBuyer] =
    useState<boolean>(false);

  const [filterType, setFilterType] = useState<string>("select");
  const [filterActive, setFilterActive] = useState<string>("select");
  const [cnpjSearch, setCnpjSearch] = useState<string>("");
  const [socialNameSearch, setSocialNameSearch] = useState<string>("");
  const [nameSearch, setNameSearch] = useState<string>("");
  const [citySearch, setCitySearch] = useState<string>("");
  const [stateSearch, setStateSearch] = useState<string>("select");
  const [groupSearch, setGroupSearch] = useState<string>("select");
  const [orderBy, setOrderBy] = useState<any>({ number: 2, order: false });
  const [cleanFilters, setCleanFilters] = useState<boolean>(true);
  const [numeroLeitos, setNumeroLeitos] = useState<string>("");
  const [site, setSite] = useState<string>("");

  const [dataAtualizacao, setDataAtualizacao] = useState("");

  const [contactCompanyFromApiSerpros, setContactCompanyFromApiSerpros] =
    useState<any[]>([]);

  const [groupCompany, setGroupCompany] = useState<any[]>([]);

  const [page, setPage] = useState<iCompanyPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });

  const [pageGroupCompany, setPageGroupCompany] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const handleSelectedCompany = (company: iCompany | null) => {
    setCompanySelected(company);
    setCnpj(company?.cnpj ?? "");
    setSocialName(company?.socialName ?? "");
    setName(company?.name ?? "");
    setActive(company?.active ?? false);
    setBuyer(company?.buyer ?? false);
    setSupplier(company?.supplier ?? false);
    setNetwork(company?.network ?? "select");
    setForeignCompany(company?.foreignCompany ?? false);
    setCodeOutdoor(company?.codeOutdoor ?? "");
    setAdministrator(company?.administrator ?? false);
    setNumeroLeitos(company?.numeroLeitos ?? "");
    setSite(company?.site ?? "");
    setDataAtualizacao(company?.dataAtualizacao ?? "");
  };

  const handleSelectedCompanyChecked = (company: any) => {
    if (company?.id === companySelected?.id) {
      setDisableButtons(true);
      return setCompanySelected(null);
    }

    setDisableButtons(false);
    setCompanySelected(company);
  };

  const getMoreCompany = async (page: iPage) => {
    try {
      setLoadingPage(true);
      const response = await companyConsumer.get(page);

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: iCompany) =>
        Company.adapterToClass(item)
      );

      setCompanys([...data, ...companys]);
    } catch {
      setCompanys([...companys]);
    } finally {
      setLoadingPage(false);
    }
  };

  const getMoreCompanyToSearch = async (page: iPage) => {
    try {
      setLoadingPage(true);
      const response = await companyConsumer.get(page);

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) => {
        return {
          id: item.id,
          label: item.nome,
        };
      });

      setCompanys([...data, ...companysToSearch]);
    } catch {
      setCompanys([...companysToSearch]);
    } finally {
      setLoadingPage(false);
    }
  };

  const getCompanys = async ({ isFilter }: { isFilter?: boolean | null }) => {
    setLoading(true);
    try {
      const filterBuyer = filterType === "buyer" ? true : undefined;
      const filterSupplier = filterType === "supplier" ? true : undefined;

      let response = await companyConsumer.get(
        page,
        cnpjSearch.trim(),
        nameSearch.trim(),
        socialNameSearch.trim(),
        filterBuyer,
        filterSupplier,
        filterActive,
        orderBy
      );

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: iCompany) =>
        Company.adapterToClass(item)
      );

      if (response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setDisableButtons(true);
      setCompanysToSearch(
        response.data.items.map((item: any) => {
          return {
            ...item,
            id: item.id,
            label: item.nome,
          };
        })
      );

      setCompanys(data);
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar as empresas cadastradas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const getMoreCompanyBuyer = async (page: iPage) => {
    try {
      setLoadingPage(true);
      const response = await companyConsumer.get(
        page,
        "",
        "",
        "",
        true,
        false,
        ""
      );

      if (response.status !== 200) throw response;

      setCompanysToSearchBuyer([
        ...companysToSearchBuyer,
        ...response.data.items
          .filter((company: any) => company.comprador)
          .map((item: any) => {
            return {
              id: item?.id ?? "",
              label: item?.nome ?? "Não informado",
              buyer: item?.comprador ?? "",
              cnpj: item?.cnpj ?? "",
            };
          }),
      ]);
    } catch {
      setCompanysToSearchBuyer([...companysToSearchBuyer]);
    } finally {
      setLoadingPage(false);
    }
  };

  const getCompanyBuyer = async (numberSumWithPage = 0) => {
    setLoadingSelectSearch(true);

    try {
      const response = await companyConsumer.get(
        { ...page, rowsPerPage: page.page + numberSumWithPage },
        cnpjSearch.trim(),
        nameSearch.trim(),
        socialNameSearch.trim(),
        true,
        false,
        "",
        null,
        citySearch.trim(),
        stateSearch.trim(),
        groupSearch
      );

      if (response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setCompanysToSearchBuyer(
        response.data.items
          .filter((company: any) => company.comprador)
          .map((item: any) => {
            return {
              ...item,
              id: item?.id ?? "",
              label: item?.nome ?? "Não informado",
              buyer: item?.comprador ?? "",
              cnpj: item?.cnpj ?? "",
            };
          })
          .sort((a: any, b: any) => a.label.localeCompare(b.label))
      );
    } catch {
      setCompanysToSearchBuyer([]);
    } finally {
      setLoadingSelectSearch(false);
    }
  };

  const getMoreCompanySupplier = async (page: iPage) => {
    try {
      setLoadingPage(true);
      const response = await companyConsumer.get(
        page,
        "",
        "",
        "",
        false,
        true,
        ""
      );

      if (response.status !== 200) throw response;

      setCompanysToSearchSupplier([
        ...companysToSearchSupplier,
        ...response.data.items
          .filter((company: any) => company.fornecedor)
          .map((item: any) => {
            return {
              id: item.id,
              label: item.nome,
              buyer: item.fornecedor,
            };
          }),
      ]);
    } catch {
      setCompanysToSearchSupplier([...companysToSearchSupplier]);
    } finally {
      setLoadingPage(false);
    }
  };

  const getCompanySupplier = async (numberSumWithPage = 0) => {
    try {
      const response = await companyConsumer.get(
        { ...page, rowsPerPage: page.page + numberSumWithPage },
        "",
        nameSupplySearch.trim(),
        "",
        false,
        true,
        ""
      );

      if (response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setCompanysToSearchSupplier(
        response.data.items
          .filter((company: any) => company.fornecedor)
          .map((item: any) => {
            return {
              ...item,
              id: item.id,
              label: item.nome,
              supplier: item.fornecedor,
            };
          })
      );
    } catch {
      setCompanysToSearchSupplier([]);
    }
  };

  const handleSalveOrUpdatedUser = async (e: any, areas?: any[]) => {
    e?.preventDefault();

    if (!buyer && !supplier) {
      return toast.warning(
        "Os campos de comprador ou fornecedor precisam estar marcados!"
      );
    }

    if (companySelected?.id) {
      handleUpdatedUser(areas);
    } else {
      handleSalveNewCompany(areas);
    }
  };

  const handleSalveNewCompany = async (areas?: any[]) => {
    try {
      setLoading(true);

      const body = {
        cnpj: cnpj?.replace(/[^a-zA-Z0-9]/g, ""),
        socialName,
        name,
        active,
        buyer,
        supplier,
        network,
        administrator,
        foreignCompany,
        codeOutdoor,
        numeroLeitos,
        site,
      };

      const response = await companyConsumer.created(body);

      if (response.status !== 200) throw response;

      setCompanySelected(response.data);

      if (contactCompanyFromApiSerpros.length !== 0) {
        await handleNewSalveApi(response.data.id);
        await handleSalveContact(
          response.data.id,
          contactCompanyFromApiSerpros,
          areas
        );
      }

      setActiveTabsMenu(false);
      setFilterActive("select");
      toast.success("Empresa cadastrada com sucesso!");
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? "Erro ao cadastrar nova empresa!"
      );
    } finally {
      setLoading(false);
      setContactCompanyFromApiSerpros([]);
    }
  };

  const handleUpdatedUser = async (areas?: any[]) => {
    try {
      setLoading(true);

      const body = {
        ...companySelected,
        cnpj: cnpj?.replace(/[^a-zA-Z0-9]/g, ""),
        socialName,
        name,
        active,
        buyer,
        supplier,
        network,
        administrator,
        foreignCompany,
        codeOutdoor,
        numeroLeitos,
        site,
        dataAtualizacao,
      };

      const response = await companyConsumer.updated(body);

      if (response.status !== 200) throw response;

      setCompanySelected(response.data);

      if (contactCompanyFromApiSerpros.length !== 0) {
        await handleNewSalveApi(response.data.id);
        await handleSalveContact(
          response.data.id,
          contactCompanyFromApiSerpros,
          areas
        );
      }

      setDataAtualizacao("");

      setFilterActive("select");
      toast.success("Empresa alterada com sucesso!");
    } catch (e: any) {
      toast.error(e?.response?.data?.message ?? "Erro ao alterar empresa!");
    } finally {
      setLoading(false);
      setContactCompanyFromApiSerpros([]);
    }
  };

  const handleActiveCompany = async () => {
    try {
      setLoading(true);

      const body = {
        ...companySelected,
        active: true,
      };

      const response = await companyConsumer.updated(body as iCompany);

      if (response.status !== 200) throw response;

      toast.success("Empresa ativada com sucesso!");
      setFilterActive("select");
      handleSelectedCompanyChecked(null);
      handleSelectedCompany(null);
      getCompanys({ isFilter: false });
    } catch (e: any) {
      toast.error(e?.response?.data?.message ?? "Erro ao ativar empresa!");
    } finally {
      setLoading(false);
    }
  };

  const handleDisabledCompany = async () => {
    try {
      setLoading(true);

      const body = {
        ...companySelected,
        active: false,
      };

      const response = await companyConsumer.updated(body as iCompany);

      if (response.status !== 200) throw response;

      toast.success("Empresa desativada com sucesso!");
      setFilterActive("select");
      handleSelectedCompanyChecked(null);
      handleSelectedCompany(null);
      getCompanys({ isFilter: false });
    } catch (e: any) {
      toast.error(e?.response?.data?.message ?? "Erro ao desativar empresa!");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCompany = async () => {
    try {
      setLoading(true);

      const response = await companyConsumer.deleted(companySelected?.id);

      if (response.status !== 200) throw response;

      toast.success("Empresa deletada com sucesso!");
      setFilterActive("select");
      handleSelectedCompanyChecked(null);
      handleSelectedCompany(null);

      setPage({
        ...page,
        page: 0,
        change: true,
      });

      getCompanys({ isFilter: false });
    } catch (e: any) {
      toast.error(e?.response?.data?.message ?? "Erro ao deletar empresa!");
    } finally {
      setLoading(false);
    }
  };

  const handleSortData = (data: { id: string; order: string }) => {
    const { id, order } = data;

    // ASC: < & >
    // DESC: > & <
    if (order === "asc") {
      const dataOrder = companys.sort(function (a: any, b: any) {
        if (a[id] < b[id]) {
          return -1;
        }
        if (a[id] > b[id]) {
          return 1;
        }
        return 0;
      });

      setCompanys([...dataOrder]);
    } else {
      const dataOrder = companys.sort(function (a: any, b: any) {
        if (a[id] > b[id]) {
          return -1;
        }
        if (a[id] < b[id]) {
          return 1;
        }
        return 0;
      });

      setCompanys([...dataOrder]);
    }
  };

  const autentichationInApiSerpros = async () => {
    setLoading(true);

    try {
      const response = await companyConsumer.AuthTokenGovBR();

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      )
        throw response;

      localStorage.setItem(
        localStorageStrings.apiTokenSerpros,
        response.data.access_token
      );

      const types = await getTypes();
      await handleVerifyCnpj(types);
    } catch (e: any) {
      toast.warning(
        e?.response?.data?.message ?? "Erro ao se autenticar na API da Serpros!"
      );
    } finally {
      setLoading(false);
    }
  };

  function isDateOlderThan90DaysOr3Months(dateIsoString: string) {
    const updatedDate = parseISO(dateIsoString);
    const datePlus3Months = addMonths(updatedDate, 3);

    const now = new Date();
    return isAfter(now, datePlus3Months);
  }

  const handleVerifyCnpj = async (types: any[]) => {
    if (!Company.isCNPJ(cnpj)) {
      return toast.warning("CNPJ digitado é invalído");
    }

    if (dataAtualizacao) {
      if (!isDateOlderThan90DaysOr3Months(dataAtualizacao)) {
        return toast.warning(
          "A última atualização foi a menos de 3 meses, não é possível consultar nesse momento!"
        );
      }
    }

    setLoading(true);

    try {
      const response = await companyConsumer.verifyCnpj(
        cnpj?.replace(/[^a-zA-Z0-9]/g, "")
      );

      if (response?.status !== 200) throw response;

      const { data } = response;

      if (data.situacaoCadastral.codigo !== "2") {
        router(`/${lang}/settings/companys`);
        return Swal.fire({
          title: "Atenção",
          text: "Empresa com situação cadastral irregular, não será possível cadastrar!",
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: palette.primary.main,
          confirmButtonText: "Ok",
        });
      }

      setSocialName(data?.nomeEmpresarial);
      setName(data?.nomeFantasia ? data?.nomeFantasia : data?.nomeEmpresarial);
      setActive(true);
      setDataAtualizacao(new Date().toISOString());

      setCep(data?.endereco?.cep);
      setAddress(data?.endereco?.logradouro);
      setCity(data?.endereco?.municipio?.descricao);
      setNeighborhood(data?.endereco?.bairro);
      setNumber(data?.endereco?.numero);
      setState(data?.endereco?.uf);
      setCountry("BR");
      setMainAddress(false);
      setRepresentative(true);
      setMain(false);

      data?.correioEletronico
        ? setEmail(data?.correioEletronico)
        : setEmail("temporario@cadastroreceita.com.br");

      const typeId = types.find(
        (e: any) => e.descricao === "Administrativo"
      )?.id;

      if (typeId) {
        setTypeId(typeId);
      }

      if (data?.socios?.length) {
        setContactCompanyFromApiSerpros([...data?.socios, ...data?.telefones]);
      } else {
        toast.warning("CNPJ não tem sócios cadastrados!");
        toast.info(
          "Favor selecionar comprador e/ou fornecedor, e clicar em salvar!"
        );
      }
    } catch (e: any) {
      if (e?.response?.status === 401) {
        return autentichationInApiSerpros();
      } else {
        toast.error(
          e?.response?.data?.message ??
            "Falha ao buscar dados do CNPJ, ou o serviço está indisponível, tente novamente mais tarde!"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getCompanyToCnpj = async (cnpj: string) => {
    setLoading(true);

    try {
      const response = await companyConsumer.get(page, cnpj);

      if (response.status !== 200) throw response;

      return response.data.items[0];
    } catch (error: any) {
      toast.info(error?.response?.data?.message ?? "Empresa não encontrada!");
    } finally {
      setLoading(false);
    }
  };

  const getGroupCompany = async () => {
    setLoading(true);

    try {
      const response = await companyGroupConsumer.get({
        ...pageGroupCompany,
        rowsPerPage: pageGroupCompany.rowsPerPage + 50,
      });

      if (response.status !== 200) throw response;

      setGroupCompany(response.data.items);
    } catch (error: any) {
      toast.info(
        error?.response?.data?.message ?? "Error ao retornar os grupos!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      companySelected,
      setCompanySelected,
      handleSelectedCompany,
      handleSelectedCompanyChecked,
      disableButtons,
      setDisableButtons,
      value,
      setValue,
      companys,
      setCompanys,
      loading,
      setLoading,
      loadingSelectSearch,
      setLoadingSelectSearch,
      getCompanys,
      cnpj,
      setCnpj,
      active,
      setActive,
      socialName,
      setSocialName,
      name,
      setName,
      nameBuyer,
      setNameBuyer,
      buyer,
      setBuyer,
      supplier,
      setSupplier,
      handleSalveOrUpdatedUser,
      activeTabsMenu,
      handleActiveCompany,
      handleDisabledCompany,
      handleDeleteCompany,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      filterType,
      setFilterType,
      filterActive,
      setFilterActive,
      network,
      setNetwork,
      companysToSearch,
      setCompanysToSearch,
      setPage,
      companysToSearchSupplier,
      companysToSearchBuyer,
      administrator,
      setAdministrator,
      loadingPage,
      setLoadingPage,
      getMoreCompany,
      getMoreCompanyBuyer,
      getMoreCompanySupplier,
      getCompanyBuyer,
      handleSortData,
      getMoreCompanyToSearch,
      clickButtonSearch,
      setClickButtonSearch,
      handleVerifyCnpj,
      setCompanysToSearchBuyer,
      codeOutdoor,
      setCodeOutdoor,
      foreignCompany,
      setForeignCompany,
      contactCompanyFromApiSerpros,
      setContactCompanyFromApiSerpros,
      getCompanySupplier,
      openDialogCompanyBuyer,
      setOpenDialogCompanyBuyer,
      typingTimeout,
      setTypingTimeout,
      nameSupplySearch,
      setNameSupplySearch,
      orderBy,
      setOrderBy,
      cnpjSearch,
      setCnpjSearch,
      socialNameSearch,
      setSocialNameSearch,
      nameSearch,
      setNameSearch,
      cleanFilters,
      setCleanFilters,
      getCompanyToCnpj,
      groupCompany,
      setGroupCompany,
      getGroupCompany,
      pageGroupCompany,
      setPageGroupCompany,
      citySearch,
      setCitySearch,
      stateSearch,
      setStateSearch,
      groupSearch,
      setGroupSearch,
      numeroLeitos,
      setNumeroLeitos,
      site,
      setSite,
    };
  }, [
    companySelected,
    orderBy,
    disableButtons,
    value,
    companys,
    loading,
    loadingSelectSearch,
    cnpj,
    active,
    socialName,
    name,
    nameBuyer,
    buyer,
    supplier,
    activeTabsMenu,
    page,
    filterType,
    filterActive,
    network,
    companysToSearchSupplier,
    companysToSearchBuyer,
    administrator,
    loadingPage,
    clickButtonSearch,
    codeOutdoor,
    foreignCompany,
    contactCompanyFromApiSerpros,
    openDialogCompanyBuyer,
    typingTimeout,
    nameSupplySearch,
    cnpjSearch,
    socialNameSearch,
    nameSearch,
    cleanFilters,
    groupCompany,
    pageGroupCompany,
    citySearch,
    stateSearch,
    groupSearch,
    numeroLeitos,
    site,
  ]);

  return (
    <CompanyContext.Provider value={contextValue}>
      {children}
    </CompanyContext.Provider>
  );
}

export default CompanyContext;
