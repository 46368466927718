import {
  iShoppingProcessItem,
  iShoppingProcessItemPage,
} from "src/interfaces/shoppingProcessItem";
import ShoppingProcessItem from "src/models/ShoppingProcessItem";
import shoppingProcess from "src/http/shoppingProcess";

class shoppingProcessItemConsumer {
  static get(
    page: iShoppingProcessItemPage,
    shoppingProcesSelectId: string,
    orderBy?: any,
    code?: string,
    description?: string
  ) {
    const search = new URLSearchParams();

    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", `${page.rowsPerPage}`);

    search.append("id", shoppingProcesSelectId);

    if (code) search.append("CodigoProduto", code);

    if (description) search.append("DescricaoProduto", description);

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    return shoppingProcess.get(`/ProcessoCompraItem?${search.toString()}`);
  }

  static created(shoppingProcessItem: iShoppingProcessItem) {
    return shoppingProcess.post(
      `/ProcessoCompraItem`,
      ShoppingProcessItem.adapterToBody(shoppingProcessItem)
    );
  }

  static updated(shoppingProcessItem: iShoppingProcessItem) {
    return shoppingProcess.put(
      `/ProcessoCompraItem`,
      ShoppingProcessItem.adapterToBody(shoppingProcessItem)
    );
  }

  static deleted(id: string | null | undefined) {
    return shoppingProcess.delete(`/ProcessoCompraItem?id=${id}`);
  }

  static createdList(body: any) {
    return shoppingProcess.post(`/ProcessoCompraItem/list`, body);
  }
}

export default shoppingProcessItemConsumer;
