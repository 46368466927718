/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  Checkbox,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import useConsolidateDemand from "src/hooks/useConsolidateDemand";
import Empty from "src/components/Empty";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import useShoppingProccessDemand from "src/hooks/useShoppingProccessDemand";
import { useState } from "react";
import { toast } from "react-toastify";

const SelectedHospital = ({ items }: { items: any[] }) => {
  const [itemsToExport, setItemsToExport] = useState<any[]>([]);

  const { setOpenDialogSecondary, generateCSVHospital } =
    useConsolidateDemand();

  const { shoppingProcesSelect } = useShoppingProcess();
  const { demands } = useShoppingProccessDemand();

  const handleChange = (item: any) => {
    const filteredDemands = demands
      .map((demand: any) => ({
        ...demand,
        solicitacaoCompras: demand.solicitacaoCompras?.filter(
          (el: any) =>
            el.empresaSolicitacao.cnpj === item.empresaSolicitacao.cnpj
        ),
      }))
      .filter((demand: any) => demand.solicitacaoCompras?.length > 0);

    setItemsToExport([...itemsToExport, ...filteredDemands]);
  };

  const handleUncheck = (item: any) => {
    const updatedItems = itemsToExport
      .map((demand: any) => ({
        ...demand,
        solicitacaoCompras: demand.solicitacaoCompras?.filter(
          (el: any) =>
            el.empresaSolicitacao.cnpj !== item.empresaSolicitacao.cnpj
        ),
      }))
      .filter((demand: any) => demand.solicitacaoCompras?.length > 0);

    setItemsToExport(updatedItems);
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">Selecione os Hospitais</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setOpenDialogSecondary(false);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        {items.length === 0 ? (
          <Empty />
        ) : (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>CNPJ</TableCell>
                  <TableCell>Razão Social</TableCell>
                  <TableCell>Selecione</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(
                  items
                    .reduce((uniqueMap, item) => {
                      const cnpj = item?.empresaSolicitacao?.cnpj;
                      if (cnpj && !uniqueMap.has(cnpj)) {
                        uniqueMap.set(cnpj, item);
                      }
                      return uniqueMap;
                    }, new Map())
                    .values()
                ).map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{item?.empresaSolicitacao?.cnpj}</TableCell>
                    <TableCell>
                      {item?.empresaSolicitacao?.razaoSocial}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        onChange={(e) => {
                          if (!e.target.checked) {
                            handleUncheck(item);
                            return;
                          }

                          handleChange(item);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>

      <DialogActions>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ marginTop: 2 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<CloseIcon />}
              size="small"
              onClick={() => {
                setOpenDialogSecondary(false);
              }}
            >
              Fechar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              color="primary"
              size="small"
              onClick={() => {
                if (itemsToExport.length === 0)
                  return toast.info("Favor selecione os hospitais!");

                generateCSVHospital(itemsToExport, shoppingProcesSelect);
              }}
            >
              Exportar Selecionados
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              color="primary"
              size="small"
              onClick={() => generateCSVHospital(demands, shoppingProcesSelect)}
            >
              Exportar Todos
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default SelectedHospital;
