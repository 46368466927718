/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  iShoppingProccessStatusContextProps,
  iShoppingProccessStatusPage,
} from "src/interfaces/shoppingProccessStatus";
import ShoppingProccessStatus from "src/models/ShoppingProccessStatus";
import shoppingProccessStatusPageConsumer from "src/services/shoppingProccessStatus";

const ShoppingProccessStatusContext =
  createContext<iShoppingProccessStatusContextProps>(
    {} as iShoppingProccessStatusContextProps
  );

export function ShoppingProccessStatusProvider({
  children,
}: {
  children: any;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [shoppingProccessStatus, setShoppingProccessStatuss] = useState<
    Array<ShoppingProccessStatus>
  >([]);
  const [page, setPage] = useState<iShoppingProccessStatusPage>({
    page: 0,
    rowsPerPage: 30,
    total: 0,
    change: true,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const getShoppingProccessStatus = async () => {
    setLoading(true);
    try {
      const response = await shoppingProccessStatusPageConsumer.get(page);

      if (response.status !== 200) throw response;

      const data = response.data.items?.map((item: any) =>
        ShoppingProccessStatus.adapterToClass(item)
      );

      if (page.total === 0 && response?.data?.totalItems !== 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
          change: false,
        });
      }

      setShoppingProccessStatuss(
        data.sort((a: any, b: any) =>
          a.description.localeCompare(b.description)
        )
      );
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar os status cadastrados!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getShoppingProccessStatus,
      loading,
      setLoading,
      shoppingProccessStatus,
      handleChangePage,
      handleChangeRowsPerPage,
    };
  }, [page, loading, shoppingProccessStatus]);

  return (
    <ShoppingProccessStatusContext.Provider value={contextValue}>
      {children}
    </ShoppingProccessStatusContext.Provider>
  );
}

export default ShoppingProccessStatusContext;
